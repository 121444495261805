<template>
  <main class="hiring-page">
    <section id="jumbotron" class="row jumbotron">
      <div class="jumbotronContainer">
        <div class="left">
          <img src="../assets/img/object5.png" />
        </div>
        <div class="right">
          <h1>
            SCIFIN
          </h1>
          <h3>Paths with purpose</h3>
        </div>
      </div>
    </section>
    <section id="position" class="position">
      <div class="prologue">
        <h2>
          We are looking for like-minded people who are <span class="bold">entrepreneurially adventurous, intellectually
            curious, critical
            thinkers, and problem solvers.</span>
        </h2>
      </div>
      <div class="typeContainer">
          <div class="type">
            <img src="../assets/img/object-qd.png" />
            <h2>Quantitative Developer</h2>
          </div>
          <div class="type">
            <img src="../assets/img/object-qr.png" />
            <h2>Quantitative Researcher</h2>
          </div>
          <div class="type">
            <img src="../assets/img/object-fed.png" />
            <h2>Frontend Developer</h2>
          </div>
        </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/pages/LandingPage/HiringPage.scss";
</style>
