import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./pages/HomePage.vue";
import HiringPage from "./pages/HiringPage.vue";
import ContactPage from "./pages/ContactPage.vue";
import TheHeader from './pages/components/TheHeader';
import TheFooter from './pages/components/TheFooter';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // Homepage
    {
      name: 'Home',
      path: "/",
      components: { default: HomePage, header: TheHeader, footer: TheFooter },
    },
    {
      name: 'Hiring',
      path: "/hiring",
      components: { default: HiringPage, header: TheHeader, footer: TheFooter },
    },
    {
      name: 'Contact',
      path: "/contact",
      components: { default: ContactPage, header: TheHeader, footer: TheFooter },
    },
    { path: "/:notFound(.*)", redirect: "/" }, // our-domain.com/user => UsersList
  ],
  linkActiveClass: "active",
});


export default router;
