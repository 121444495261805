<template>
  <main class="landing-page">
    <section id="jumbotron" class="row jumbotron">
      <div class="jumbotronContainer">
        <div class="left">
          <h1>
            Connecting Businesses <br />
            to the Global Web3 Ecosystem
          </h1>
          <a @click="goto('services')" class="btn focus">Explore Our Services</a>
        </div>
        <div class="right">
          <img src="../assets/img/object1.png" />
        </div>
      </div>
    </section>
    <section ref="about" class="about">
      <div class="row">
        <div class="col-4"><img src="../assets/img/object2.png" /></div>
        <div class="col-6">
          <h1>About Us</h1>
          <p>
            <span class="bold">SCIFIN</span> is the investment bank of the decentralized economy. <span
              class="bold">SCIFIN</span> helps realize the potentials of
            traditional businesses using public blockchain protocols. To do so, we provide end to end solutions, from
            ideation, development, and preparation stages to liquidity provision. We believe this approach will allow
            the adoption of blockchain technology further into mainstream business cases.
          </p>
        </div>
      </div>
    </section>
    <section ref="services" class="services">
      <h1>Our Services</h1>
      <div class="row">
        <div class="col-5">
          <div class="card">
            <img src="../assets/img/object-consulting.png" />
            <div>
              <h3>Consulting & Advisory</h3>
              <p>
                Creating strategy and actionable plans to utilize public blockchain protocol for your projects and
                businesses
              </p>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="card">
            <img src="../assets/img/object-development.png" />
            <div>
              <h3>Technical Development</h3>
              <p>Implementing bespoke design and engineering solutions tailored to your specific requirements</p>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="card">
            <img src="../assets/img/object-partnership.png" />
            <div>
              <h3>Partnership Management</h3>
              <p>Connecting you with the right partners to boost the presence of your projects, ranging from PR,
                marketing management, and community engagement</p>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="card">
            <img src="../assets/img/object-liquidity.png" />
            <div>
              <h3>Liquidity Provision & Management</h3>
              <p>Providing liquidity to spot and derivative markets as an independent and / or a designated market maker
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section ref="vision" class="vision">
      <div class="row">
        <div class="col-5 content">
          <h1>Our Vision</h1>
          <p>
            We believe that the usage of public blockchain technology will converge towards seamless experience as many
            other technologies that we now take as granted have. The decentralized nature of the blockchain ecosystem
            requires the producers of goods and services to be able to build, promote, and maintain their applications.
            With <span class="bold">SCIFIN</span>, we bring our expertise in liquidity provision activities to ensure
            that every project within
            our pipelines can be released as a potentially sustainable business.
          </p>
        </div>
        <div class="col-5 image" />
      </div>
    </section>
    <section ref="liquidity" class="liquidity">
      <div class="row">
        <div class="col-5 image" />
        <div class="col-5 content">
          <h1>Market Making / Liquidity Provision</h1>
          <p>
            Active liquidity provision is an essential part of the ecosystem. We build our own internal pricing and
            quoting engine from the ground up for both electronic and OTC market making activities. Our engine is
            capable of quoting a tight spread across various products and instruments efficiently.
          </p>
          <div class="card">
            <img src="../assets/img/object-token.png" />
            <div>
              <h3>Individual Token Products</h3>
              <p>
                <span class="bold">SCIFIN</span> provides liquidity on delta one products, such as cash, perpetual
                swaps, futures, and options.
                Our internal pricing engine enables us to specialize in dynamic quoting price and sizes, without
                spamming the target order book matching engine. This results in optimal capital efficiency with tight
                spread and deep quotes.
              </p>
            </div>
          </div>
          <div class="card">
            <img src="../assets/img/object-nft.png" />
            <div>
              <h3>NFT Collection</h3>
              <p>
                <span class="bold">SCIFIN</span> advises NFT projects on managing their internal designated inventory
                wallet to maintain the floor
                and average price of their collection.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section ref="hiring" class="hiring">
      <h2>
        We are looking for like-minded people who are <span class="bold">entrepreneurially adventurous, intellectually
          curious, critical
          thinkers, and problem solvers.</span>
      </h2>
      <router-link class="btn" to="/hiring">Work at SciFin</router-link>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    goto(refName) {
      let top = 0
      if (refName !== null) {
        const element = this.$refs[refName];
        top = element.offsetTop - 100;
      }
      window.scrollTo({ top: top, behavior: 'smooth' });
    },
    asyncData() {
      if (this.$route.name === 'Home') {
        if (this.$route.hash) {
          const hash = (this.$route.hash).replace('#', '');
          this.goto(hash)
          return;
        }
        this.goto(null);
      }
    }
  },
  watch: {
    // call the method if the route changes
    '$route': {
      handler: 'asyncData',
      immediate: false // runs immediately with mount() instead of calling method on mount hook
    }
  },
  created(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/pages/LandingPage/HomePage.scss";
</style>
