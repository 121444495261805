<template>
  <header>
    <router-view name="header"></router-view>
  </header>
  <main>
    <router-view></router-view>
  </main>
  <footer>
    <router-view name="footer"></router-view>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "./scss/app.scss";

.notif-container-parent {
  position: fixed;
  z-index: 999;
  bottom: 50px;
}
</style>
