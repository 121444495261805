<template>
  <main class="contact-page">
    <section id="jumbotron" class="row jumbotron">
      <div class="jumbotronContainer">
        <div class="left">
          <img src="../assets/img/object6.png" />
        </div>
        <div class="right">
          <h1>
            Get in touch
          </h1>
          <h5>Have any question? We'd love to hear from you</h5>
        </div>
      </div>
    </section>
    <section id="position" class="position">
      <div class="prologue">
        <h2>
          Send us a message and
          <span class="bold">
            we will be in touch within one business day
          </span>
        </h2>
      </div>
      <div class="typeContainer">
        <div class="type">
          <img src="../assets/img/object-mm.png" />
          <h2>Market Making</h2>
          <a href="mailto:mm@scifin.co">mm@scifin.co</a>
        </div>
        <div class="type">
          <img src="../assets/img/object-business.png" />
          <h2>Business</h2>
          <a href="mailto:business@scifin.co">business@scifin.co</a>
        </div>
        <div class="type">
          <img src="../assets/img/object-hiring.png" />
          <h2>Hiring</h2>
          <a href="mailto:hiring@scifin.co">hiring@scifin.co</a>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/pages/LandingPage/ContactPage.scss";
</style>
