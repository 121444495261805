<template>
  <footer>
    <nav class="bottom">
      <div class="wrap">
        <p>
          Copyright © 2024 SciFin Technologies - PT SCIFIN TEKNOLOGI INDONESIA. All
          rights reserved.
        </p>
      </div>
    </nav>
  </footer>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../scss/components/TheFooter.scss";
</style>
