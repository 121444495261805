<template>
  <header>
    <nav class="header">
      <div class="navLeftContainer">
        <router-link class="logo" to="/"><img alt="ECOFIT" src="../../assets/img/logo.png" /></router-link>
        <div class="menu">
          <router-link class="menuButton" to="/#about">About</router-link>
          <router-link class="menuButton" to="/#services">Our Services</router-link>
          <router-link class="menuButton" to="/#vision">Our Vision</router-link>
          <router-link class="menuButton" to="/#liquidity">Liquidity Provision</router-link>
        </div>
      </div>
      <div class="navRightContainer">
        <router-link class="btn" to="/hiring">Work at SciFin</router-link>
        <router-link class="btn focus" to="/contact">Partner with SciFin</router-link>
      </div>
    </nav>
    <a @click="openMenu" class="button-menu"><i class="fas fa-bars"></i></a>
    <nav class="menu" :class="{ 'open-menu': menuOpen }">
      <a @click="closeMenu" class="button-close"><i class="fas fa-times"></i></a>
      <div>
        <router-link @click="closeMenu" class="menuButton" to="/#about">About</router-link>
      </div>
      <div>
        <router-link @click="closeMenu" class="menuButton" to="/#services">Our Services</router-link>
      </div>
      <div>
        <router-link @click="closeMenu" class="menuButton" to="/#vision">Our Vision</router-link>
      </div>
      <div>
        <router-link @click="closeMenu" class="menuButton" to="/#liquidity">Liquidity Provision</router-link>
      </div>
      <div>
        <router-link @click="closeMenu" class="btn" to="/hiring">Work at SciFin</router-link>
      </div>
      <div>
        <router-link @click="closeMenu" class="btn focus" to="/contact">Partner with SciFin</router-link>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    openMenu() {
      this.menuOpen = true;
    },
    closeMenu() {
      this.menuOpen = false;
    },
  },
  computed: {
    openMenuClass() {
      if (this.menuOpen) {
        return "open-menu";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/components/TheHeader.scss";
</style>
